import React, { useState } from "react";
import "./App.css";
import CryptoForm from "./CryptoForm";
import Defaultpage from "./Defaultpage";
import axios from "axios";

function App() {
  const [active, setActive] = useState("uused");
  const [hash, setHash] = useState("");
  const [trans, setTrans] = useState("");
  const get_trans = async (e) => {
    e.preventDefault();
    if (hash === "") {
      alert("Enter a valid transaction hash");
    } else {
      const trans_details = await axios.get(
        "https://api.blockcypher.com/v1/btc/main/txs/" + hash
      );
      setTrans(trans_details);
      console.log(trans_details); //test hash: f854aebae95150b379cc1187d848d58225f3c4157fe992bcd166f58bd5063449
      setActive("used");
    }
  };

  return (
    <div className="crypto">
      <div className="heading">CRYPTO OVERRIDE</div>
      <div className="box">
        <div className="flex">
          <input
            placeholder="Transaction hash"
            value={hash}
            onChange={(e) => setHash(e.target.value)}
            className="input"
          />
          <button className="btn" onClick={(e) => get_trans(e)}>
            <span className="search">GO</span>
          </button>
        </div>

        <div className=" intro-text">
          {active === "used" && <CryptoForm transaction={trans} />}
          {active === "uused" && <Defaultpage />}
        </div>
      </div>
    </div>
  );
}

export default App;
