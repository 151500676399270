import React from "react";
import "./default.css";

function Defaultpage() {
  return (
    <div className="comtainer">
      <div className="contemt">
        <li>Enter transaction hash and click go to continue</li>
        <li>Enter transaction hash and click go to continue</li>
        <li>Enter transaction hash and click go to continue</li>
        <li>Enter transaction hash and click go to continue</li>
        <li>Enter transaction hash and click go to continue</li>
        <li>Enter transaction hash and click go to continue</li>
        <li>Enter transaction hash and click go to continue</li>
      </div>
    </div>
  );
}

export default Defaultpage;
